import consumer from "../admin_channels/consumer";

let modal = null;

consumer.subscriptions.create("ImpersonateChannel", {
  connected() {
    const impersonateDetails = document.getElementById("impersonate-details");
    if (impersonateDetails) {
      const modalElement = document.createElement("impersonate-modal");
      modal = document.body.appendChild(modalElement);
    };
  },

  disconnected() {
    if (modal !== null) modal.remove();
  },

  received(data) {
    const impersonateDetails = document.getElementById("impersonate-details");
    if (impersonateDetails) {
      const userId = Number(impersonateDetails.getAttribute("data-impersonating-id"));
      const adminUserId = Number(impersonateDetails.getAttribute("data-impersonator-id"));
      const sessionExpired = data.admin_user_id === adminUserId && ((data.action === "start" && data.user_id !== userId) || (data.action === "stop" && data.user_id === userId));

      if (sessionExpired) {
        impersonateDetails.setAttribute("data-impersonating-id", ""); // block any double or re-impersonate attempts
        // Slight timeout so we don't flash the modal while the user is waiting for admin to load.
        setTimeout(() => {
          modal.dispatchEvent(new CustomEvent("open"));
        }, 2000);
      }
    }
  },
});
